import React, { useState, useEffect } from "react";

// core components
import Button from "components/CustomButtons/Button.js";
import Modal from "components/Modal/Modal.js";
import CCchargeForm from "components/CCchargeForm/CCchargeForm";
import Notification from "components/Notification/Notification";
import ActionAlerts, { AlertSeverity } from "components/ActionAlerts/ActionAlerts";

const SUCCESS_MSG = "Charge applied successfully";

export default function CCcharge({ formik, isLocked = false }) {
    const [notification, setNotification] = useState({
        duration: 1000000,
        open: false,
        severity: AlertSeverity.SUCCESS,
        message: SUCCESS_MSG,
    });
    const [openModal, setOpenModal] = useState(false);

    return (
        <>
            <Modal
                open={openModal}
                modalTitle={"CC charge"}
                handleClose={() => {
                    setOpenModal(false);
                }}
            >
                {openModal ? (
                    <>
                        <div style={{ minWidth: 600 }}></div>
                        {notification.open && (
                            <ActionAlerts
                                message={notification.message}
                                severity={notification.severity}
                                autoCloseTime={null}
                                onClose={() => setNotification({ ...notification, open: false })}
                            />
                        )}
                        <CCchargeForm
                            dealerId={formik.values.dealerId}
                            customerId={formik.values.customerId}
                            orderId={formik.values.id}
                            fullAmount={formik.values.orderAmount}
                            onSuccess={(message) =>
                                setNotification({
                                    ...notification,
                                    open: true,
                                    severity: AlertSeverity.SUCCESS,
                                    message,
                                })
                            }
                            onError={(message) =>
                                setNotification({
                                    ...notification,
                                    open: true,
                                    severity: AlertSeverity.ERROR,
                                    message,
                                })
                            }
                        />
                    </>
                ) : null}
            </Modal>

            <Button
                type="button"
                color="linkedin"
                onClick={() => setOpenModal(true)}
                disabled={isLocked}
            >
                CC Charge
            </Button>
        </>
    );
}
