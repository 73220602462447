import { useEffect, useState, useMemo } from "react";
import PageLayout from "layouts/PageLayout";
import useDealerId from "hooks/useDealerId";
import useDealerRoles from "hooks/useDealerRoles";
import { useSnackbar } from "hooks/useSnackbar";

// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import SelectAllCheckbox from "components/SelectAllCheckbox/SelectAllCheckbox";
import RowCheckbox from "components/RowCheckbox/RowCheckbox";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button.js";

export default function DealerRoles() {
    const openSnackbar = useSnackbar();
    const dealerId = useDealerId();
    const { roles = [], createRole, deleteRole, getRoles } = useDealerRoles();

    const rolesParsed = useMemo(() => {
        return roles.filter((dr) => dr.roleType === "dealer" && !dr.dealerId);
    }, [roles]);

    const dealerRoles = useMemo(() => {
        return roles.filter((d) => d.dealerId == dealerId);
    }, [roles, dealerId]);

    const [checked, setChecked] = useState(false);
    const [selected, setSelected] = useState([]);

    // Manejo del estado de checkboxes
    useEffect(() => {
        if (dealerRoles.length > 0) {
            const matchingRoleIds = rolesParsed
                .filter((roleParsed) =>
                    dealerRoles.some((dealerRole) => dealerRole.name === roleParsed.name)
                )
                .map((role) => role.id);

            // Actualizamos los seleccionados si cambian
            setSelected(matchingRoleIds);
        }
    }, [dealerRoles, rolesParsed]);

    const columns = [
        {
            Header: () => (
                <SelectAllCheckbox
                    checked={checked}
                    setChecked={setChecked}
                    selected={selected}
                    setSelected={setSelected}
                    items={rolesParsed} // Usamos rolesParsed en lugar de roles completos
                />
            ),
            accessor: "isChecked",
            Cell: ({ row: { original } }) => (
                <RowCheckbox original={original} selected={selected} setSelected={setSelected} />
            ),
            getTdProps: () => ({
                style: {
                    width: "50px",
                    padding: "0",
                },
            }),
        },
        {
            Header: "Role name",
            accessor: "name",
        },
        {
            Header: "Role type",
            accessor: "roleType",
        },
        {
            Header: "",
            accessor: "others",
        },
    ];

    const handleSave = async () => {
        // Identificar los roles seleccionados
        const selectedRoles = rolesParsed.filter((role) => selected.includes(role.id));

        // Añadir roles nuevos (marcados)
        for (const role of selectedRoles) {
            const roleParsed = {
                dealerId: dealerId,
                name: role.name,
                menuOptions: role.menuOptions,
                roleType: "dealer",
                roleParentId: role.id,
            };

            if (!dealerRoles.some((dealerRole) => dealerRole.name === role.name)) {
                try {
                    await createRole(roleParsed, () => {});
                } catch (error) {
                    console.error(`Error creando el rol ${role.id}:`, error);
                }
            }
        }

        // Eliminar roles desmarcados
        const unselectedRoles = dealerRoles.filter(
            (dealerRole) => !selectedRoles.some((role) => role.name === dealerRole.name)
        );

        for (const role of unselectedRoles) {
            try {
                await deleteRole(role.id, () => {});
            } catch (error) {
                console.error(`Error eliminando el rol ${role.id}:`, error);
            }
        }

        openSnackbar("success", "Dealer roles updated successfully.");

        // Refrescar la lista de roles después de guardar
        await getRoles();
    };

    return (
        <PageLayout>
            <ReactTable
                columns={columns}
                data={!dealerId || dealerId == 0 ? [] : rolesParsed}
                showPagination={true}
                showSorting={false}
            />
            <GridContainer style={{ marginTop: 20 }}>
                <GridItem xs={12} sm={12} md={12} style={{ textAlign: "center", marginTop: 5 }}>
                    <Button
                        type="button"
                        color="rose"
                        onClick={handleSave}
                        disabled={!dealerId || dealerId == 0}
                    >
                        Guardar
                    </Button>
                </GridItem>
            </GridContainer>
        </PageLayout>
    );
}
