import http, { BASE_URL_API_ECOMMERCE } from "./Http";

const post = async ({ dealerId, orderId, userId, transactionAmount, reason }) => {
    const res = await http
        .post(`/merchante-api`, {
            dealerId,
            orderId: Number(orderId),
            userId,
            transactionAmount: Number(transactionAmount),
            reason,
        })
        .then((r) => r.data);
    return res;
};

const postAuthorizeNet = async ({
    dealerId,
    customerId,
    cardNumber,
    transactionAmount,
    cardExpDate,
    cvv2,
}) => {
    const res = await http
        .post(`${BASE_URL_API_ECOMMERCE}/authorize-net`, {
            dealerId,
            cId: customerId,
            transactionAmount: Number(transactionAmount),
        })
        .then((r) => r.data);
    return res;
};

const PaymentService = {
    post,
    postAuthorizeNet,
};

export default PaymentService;
